import React, { useState } from "react";
import mainlogo from '../assets/img/mainlogo.png'
import facbook from '../assets/img/facbook.png'
import linkedin from '../assets/img/linkedin.png'
import { Link } from "react-scroll";

const Header = () => {

    const [isOpen, setIsOpen] = useState(false);
    // const openInNewTab = (url) => {
    //     window.location.href = url; // Redirection dans l'onglet actuel
    // };
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer'); // Ouvre dans un nouvel onglet
    };
    
    const handleDownloadAndOpen = () => {
        const pdfUrl = "/documents/noelpicart.pdf";
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.download = "noelpicart.pdf";
        a.click();
        window.open(pdfUrl, "_blank", "noopener,noreferrer");
    };


    const handleOpenPaymentPopup = () => {
        const width = 800; // Largeur de la popup
        const height = 600; // Hauteur de la popup
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
      
        // Ouvrir une popup avec la page de paiement
        window.open(
        //   "http://localhost:3000/paiement", // URL de la page de paiement
          "https://noelpicart.fr/paiement", // URL de la page de paiement
          "PopupPaiement", // Nom de la popup
          `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
        );
      };
      


    return (
        <nav className="">
            {/* Barre principale */}
            <div className="w-[100%]  mx-auto px-8 sm:px-6 lg:px-1 fixed z-50 bg-[#0d0f15] border-b border-[#042a32]">
                <div className="flex items-center md:justify-center  justify-between gap-12 lg:gap-20 xl:gap-32 2xl:gap-36 2xl:justify-center  xl:justify-center lg:justify-center   h-16 mt-4">
                    {/* Logo */}
                    <div className="flex-shrink-0 cursor-pointer">
                        <img
                            src={mainlogo} // Remplacez par le chemin de votre image
                            alt="Logo MonSite"
                            className="h-14 w-auto"
                        />
                    </div>
                    {/* Menu Items (Desktop) */}
                    <div className="hidden md:flex space-x-12 lg:space-x-5 md:space-x-4  xl:space-x-5 2xl:space-x-16">
                        <Link to="accueil" smooth={true}
                            duration={200} className="text-[#9a9a9a]  py-2 lg:text-xl text-sm font-medium hover:text-white cursor-pointer"
                        >
                            Accueil
                        </Link>
                        <Link className="text-[#9a9a9a]  py-2 rounded-md lg:text-lg  text-sm font-medium hover:text-white cursor-pointer" to="CV"
                            smooth={true}
                            duration={200}>
                            CV
                        </Link>
                        <Link to="bestofprojetct"
                            smooth={true}
                            duration={200} className="text-[#9a9a9a] cursor-pointer  py-2 rounded-md lg:text-lg  text-sm font-medium hover:text-white">
                            Best Of Projects
                        </Link>
                        <Link to="contactform"
                            smooth={true}
                            duration={200} className="text-[#9a9a9a] cursor-pointer  py-2 rounded-md lg:text-lg  text-sm font-medium hover:text-white">
                            Nous Contacter
                        </Link>

                        <button

                            className="text-[#9a9a9a]  py-2 rounded-md lg:text-lg text-sm font-medium hover:text-white cursor-pointer"
                            onClick={handleDownloadAndOpen}


                        >
                            PDF
                        </button>
                        <button
                            onClick={handleOpenPaymentPopup}
                            className="text-[#9a9a9a] cursor-pointer px-1 py-2 rounded-md lg:text-lg text-sm font-medium hover:text-white"
                        >
                            Réglement en Ligne
                        </button>

                    </div>
                    <div className="flex-shrink-0 flex gap-4 hidden md:flex">
                        <Link to="footer"
                            onClick={() => openInNewTab("https://www.linkedin.com/in/noelpicart/")}

                            smooth={true}
                            duration={200} className="text-[#9a9a9a] cursor-pointer  py-2 rounded-md lg:text-lg  text-sm  font-medium hover:text-white">
                            <img
                                src={linkedin} // Remplacez par le chemin de votre image
                                alt="Logo MonSite"
                                className="h-5 w-auto"
                            />
                        </Link>
                        <Link
                            to="footer"
                            onClick={() => openInNewTab("https://www.facebook.com/NoelPicart")}

                            smooth={true}
                            duration={200} className="text-[#9a9a9a] cursor-pointer px-1 py-2 rounded-md lg:text-lg  text-sm  font-medium hover:text-white">
                            <img
                                src={facbook} // Remplacez par le chemin de votre image
                                alt="Logo MonSite"
                                className="h-5 w-auto"
                            />
                        </Link>
                    </div>
                    {/* Hamburger Menu (Mobile) */}
                    <div className="md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="text-white hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
                        >
                            <svg
                                className="h-10 w-10"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                {isOpen ? (
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                ) : (
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16m-7 6h7"
                                    />
                                )}
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="fixed inset-0 bg-[#042a32]  flex flex-col items-start py-6  px-4 z-50">
                    {/* Logo */}
                    <div className="flex items-center justify-between w-full">
                        <img
                            src={mainlogo} // Remplacez par le chemin de votre image
                            alt="Logo MonSite"
                            className="h-10 w-auto cursor-pointer"
                        />
                        <button
                            onClick={() => setIsOpen(false)}
                            className="text-white hover:text-gray-300 focus:outline-none"
                        >
                            <svg
                                className="h-8 w-8"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* Menu Items */}
                    <div className="mt-10 space-y-4  flex justify-center items-center flex-col w-full t">
                        <Link to="accueil" smooth={true}
                            duration={200} className="block cursor-pointer text-[#9a9a9a] text-lg font-medium text-justify hover:text-gray-50 px-3 py-2 rounded-md" onClick={() => setIsOpen(!isOpen)}
                        >
                            Accueil
                        </Link>
                        <Link to="CV"
                            smooth={true}
                            duration={200} className="block cursor-pointer text-[#9a9a9a] text-lg font-medium text-justify hover:text-gray-50 px-3 py-2 rounded-md" onClick={() => setIsOpen(!isOpen)}
                        >
                            CV
                        </Link>
                        <Link tto="bestofprojetct"
                            smooth={true}
                            duration={200} className="block cursor-pointer text-[#9a9a9a] text-lg font-medium hover:text-gray-50 px-3 py-2 rounded-md" onClick={() => setIsOpen(!isOpen)}
                        >
                            Best Of Projects

                        </Link>
                        <Link to="contactform"
                            smooth={true}
                            duration={200} className="block cursor-pointer text-[#9a9a9a] text-lg font-medium hover:text-gray-50 px-3 py-2 rounded-md" onClick={() => setIsOpen(!isOpen)}
                        >
                            Nous Contacter

                        </Link>
                        <button to="PDF"
                            smooth={true}
                            duration={200} className="block cursor-pointer text-[#9a9a9a] text-lg font-medium hover:text-gray-50 px-3 py-2 rounded-md" onClick={() => {
                                setIsOpen(!isOpen); // Si vous avez besoin de gérer l'état `isOpen`
                                handleDownloadAndOpen(); // Appeler la fonction pour télécharger et afficher
                            }}

                        >
                            PDF
                        </button>
                        <button to="REL"
                            onClick={() => {
                                setIsOpen(!isOpen); // Si vous avez besoin de gérer l'état `isOpen`
                                handleOpenPaymentPopup()
                            }}
                            smooth={true}
                            duration={200} className="block cursor-pointer text-[#9a9a9a] text-lg font-medium hover:text-gray-50 px-3 py-2 rounded-md"
                        >
                            Réglement en Ligne
                        </button>
                        <div className="flex ">
                            <Link to="footer"
                                smooth={true}
                                duration={200} className="block cursor-pointer text-[#9a9a9a] text-lg font-medium hover:text-gray-50 px-3 py-2 rounded-md" onClick={() => setIsOpen(!isOpen)}
                            >
                                <img
                                    src={linkedin} // Remplacez par le chemin de votre image
                                    alt="Logo MonSite"
                                    className="h-5 w-auto"
                                />
                            </Link>
                            <Link to="footer"
                                smooth={true}
                                duration={300} className="block cursor-pointer text-white text-lg font-medium hover:text-gray-50 px-3 py-2 rounded-md" onClick={() => setIsOpen(!isOpen)}
                            >
                                <img
                                    src={facbook} // Remplacez par le chemin de votre image
                                    alt="Logo MonSite"
                                    className="h-5 w-auto"
                                />                         </Link>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Header;
