import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import noel from '../assets/img/noel-acc.png'
import formtitle from '../assets/img/formtitle.png'
import fondcv from '../assets/img/fond-cv.png'
import BestOfProjects from "../components/BestOfProjects";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import api from "../api";
const Home = () => {
    const location = useLocation();
    const [paymentStatus, setPaymentStatus] = useState(null);

    useEffect(() => {
        // Récupérer les paramètres de l'URL
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get("payment");

        // Déterminer le statut du paiement
        if (status === "success") {
            setPaymentStatus("success");
        } else if (status === "cancel") {
            setPaymentStatus("cancel");
        } else {
            setPaymentStatus(null);
        }
    }, [location]);
    const [presentation, setPresentation] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await api.get("/parametres");

                setPresentation(response.data.data);

            } catch (error) {
                console.error("Erreur lors de la récupération des projets :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);
    // Nettoyer l'URL après affichage du message
    useEffect(() => {
        if (paymentStatus) {
            // Nettoie les paramètres de l'URL
            window.history.replaceState({}, document.title, "/");
    
            // Affiche un message de succès pendant 2 secondes
            setTimeout(() => {
                // alert("Paiement réussi ! Merci de votre confiance.");
    
                // Redirige vers la page principale
                if (window.opener) {
                    window.opener.location.href = "https://noelpicart.fr";
                    window.close(); // Ferme la popup actuelle
                } else {
                    // Si ce n'est pas une popup, redirige directement
                    window.location.href = "https://noelpicart.f/";
                }
            }, 1000); // Délai de 2 secondes avant la redirection
        }
    }, [paymentStatus]);
    
    return (
        <div className="flex flex-col min-h-screen bg-[#0d0f15] z-30">
            {/* Section Header */}
            <Header />

            {/* Section Main */}
            <main className=" mt-20 lg:mt-12 ">
                <section id="accueil" className="  text-white text-center lg:py-24 lg:px-4 mx-4  h-[100%] ">
                    {paymentStatus === "success" && (
                        <div className="bg-green-500 text-white p-4 rounded">
                            <h1 className="text-xl font-bold">Paiement réussi !</h1>
                            <p>Merci pour votre paiement.</p>
                        </div>
                    )}

                    {paymentStatus === "cancel" && (
                        <div className="bg-red-500 text-white p-4 rounded">
                            <h1 className="text-xl font-bold">Paiement annulé.</h1>
                            <p>Vous pouvez réessayer le paiement.</p>
                        </div>
                    )}


                    <div class=" relative z-40 w-[100%] lg:w-[100%] xl:w-[84%] 2xl:w-[84%] flex-col flex-wrap-reverse mt-5 lg:mt-1 mx-auto bg-[#00171b] border-2 border-[#042a32] " style={{ fontFamily: 'math' }}>
                        <div className="bg-black flex justify-center  items-center relative h-28 lg:h-40 w-[100%] lg:mb-3 ">
                            <div className="w-full">
                                <p className="text-[#cdcecf] lg:absolute lg:right-14 bottom-8 lg:pr-0 2xl:pr-20 xl:pr-20 text-xl font-medium text-center lg:text-2xl" style={{ fontFamily: 'sans-serif' }} dangerouslySetInnerHTML={{
                                    __html: presentation.presentation_title,
                                }} >
                                    {/* Chef De Projet Expert IT en Nouvelles Technologies */}

                                </p>
                            </div>
                        </div>
                        <div className="flex  flex-wrap-reverse  lg:h-80">
                            <div className=" w-full lg:w-[35%] flex justify-center lg:relative">
                                <img
                                    src={noel} // Remplacez ce chemin par celui de votre image
                                    alt="Portrait"
                                    className="lg:max-w-96 w-auto h-auto lg:absolute lg:-bottom-0 left-0 flex"
                                />
                            </div>
                            <div className=" w-full lg:w-[55%] lg:pr-0 pt-6 p-3">
                                <p className="lg:text-4xl text-4xl items-center text-justify lg:text-right font-medium  text-[#b5ced6] py-5" style={{ fontFamily: 'sans-serif' }} dangerouslySetInnerHTML={{
                                    __html: presentation.titre_2_presentation,
                                }}></p>
                                <p className="lg:text-[20px] text-lg text-justify lg:text-right leading-relaxed mb-4 text-[#486a72] " dangerouslySetInnerHTML={{
                                    __html: presentation.presentation_content,
                                }}>
                                  
                                </p>
                              
                            </div>
                        </div>

                    </div>
                    <img
                        src={formtitle} // Remplacez ce chemin par celui de votre image
                        alt="Portrait"
                        className=" hidden lg:flex w-auto lg:absolute lg:bottom-10 lg:right-80 z-20"
                    />


                </section>
                <section id="CV" className=" lg:py-24 py-1  text-white text-center lg:px-4 mx-4  h-[100%] ">


                    <div className="relative w-[100%] lg:w-[85%] flex flex-col mt-1 z-40 pw-3 lg:px-5  mx-auto text-[#b1c9d0] lg:my-4">
                        <div className="relative py-6">
                            {/* Image */}
                            <img
                                src={fondcv} // Remplacez ce chemin par celui de votre image
                                alt="Portrait"
                                className=" hidden lg:flex w-72 h-72 lg:absolute lg:-bottom-48  lg:-right-20 "
                            />

                            {/* Texte centré sur l'image */}
                            <div className="lg:absolute inset-0 flex items-center justify-center text-center ">
                                <div className="text-white">
                                    <h1 className="text-4xl py-4 text-start " style={{ fontFamily: 'sans-serif' }}dangerouslySetInnerHTML={{
                                    __html: presentation.cv_title,
                                }}>
                                  
                                </h1>
                                    <div className=" text-justify lg:text-start  space-y-7   " style={{ fontFamily: 'math' }}>
                                    <p className="text-lg " dangerouslySetInnerHTML={{
                                    __html: presentation.cv_content,
                                }}></p>
                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
                <section id="bestofprojetct" className="  text-white text-center lg:pt-16 lg:px-4 mx-4  h-[100%] ">

                    <div class=" relative z-40 w-[100%] lg:w-[85%] flex-col flex-wrap-reverse mt-1 pw-3 lg:px-5 lg:mt-1 mx-auto text-[#b1c9d0] " style={{ fontFamily: 'sans-serif' }}>
                        <h1 className="text-4xl py-4 text-justify ">/ Best Of Projects</h1>


                        <BestOfProjects />


                    </div>



                </section>
                <section id="contactform" className="  text-white text-center lg:pb-20 lg:px-4 mx-2  h-[100%] ">

                    <div class=" relative z-40 w-[100%] lg:w-[85%] flex-col flex-wrap-reverse mt-1 pw-3 lg:px-5 lg:mt-1 mx-auto text-[#b1c9d0] " style={{ fontFamily: 'sans-serif' }}>
                        <h1 className="text-4xl py-4 text-justify ">/ Nous Contacter</h1>


                        <ContactForm />


                    </div>



                </section>
                {/* Section Footer */}

                <section id="footer">
                    <Footer />

                </section>
            </main>
        </div >

    );
};

export default Home;
