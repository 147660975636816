
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import fondprojets from "../assets/img/fond projets.png";
import api from "../api";

const Footer = () => {
    const handleExternalLink = (url) => {
        // Ouvre les liens externes dans un nouvel onglet
        window.open(url, "_blank", "noopener,noreferrer");
    };
    const [presentation, setPresentation] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await api.get("/parametres");

                setPresentation(response.data.data);

            } catch (error) {
                console.error("Erreur lors de la récupération des projets :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);
    return (
        <footer className="bg-teal-700 text-white lg:py-16 py-4 relative">
            <div className="flex flex-col gap-y-3 lg:gap-y-6">
                <div className="flex flex-row justify-center">
                    {/* Liens principaux */}
                    <div className="flex flex-col lg:flex-row md:flex-row justify-center lg:gap-24 md:gap-16 cursor-pointer">
                        {/* Site web */}
                        <div className=" flex justify-center text-lg">
                            <Link
                                to="#"
                                onClick={() => handleExternalLink("https://noelpicart.fr")}
                                className="lg:text-2xl cursor-pointer hover:underline"

                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: presentation.footer_site,
                                    }}
                                ></span>        </Link>
                        </div>

                        {/* E-mail */}
                        <div className="flex gap-10 lg:gap-16  ">
                            <Link
                                to="#"
                                onClick={() => handleExternalLink("mailto:hello@noelpicart.fr")}
                                className="lg:text-2xl text-md cursor-pointer hover:underline "
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: presentation.footer_email,
                                    }}
                                ></span>                             </Link>

                            {/* Téléphone */}
                            <Link
                                to="#"
                                onClick={() => handleExternalLink("tel:+33638506283")}
                                className="lg:text-2xl text-md  cursor-pointer hover:underline "
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: presentation.footer_phone,
                                    }}
                                ></span>                             </Link>
                        </div>
                    </div>

                    {/* Image décorative */}
                    <div className="">
                        <img
                            src={fondprojets}
                            alt="Fond Projets"
                            className="hidden md:hidden xl:flex w-auto lg:absolute bottom-20 md:right-0 lg:right-1 xl:right-1 2xl:right-10 cursor-pointer"
                        />
                    </div>
                </div>

                {/* Navigation interne avec <Link> */}

                {/* Informations légales */}
                <div className="lg:text-lg text-sm justify-center items-center cursor-pointer">
                    <div className="text-center text-lg lg:text-lg flex lg:flex-row gap-2 justify-center flex-col lg:space-y-0 ">
                        <p>    <span
                            dangerouslySetInnerHTML={{
                                __html: presentation.footer_rcs,
                            }}
                        ></span> </p>
                        <p>     <span
                            dangerouslySetInnerHTML={{
                                __html: presentation.footer_ape,
                            }}
                        ></span>
                        </p>
                      
                    </div>
                    <div className="text-center text-lg lg:text-lg flex  my-3 justify-center flex-col">
                       
                        <p>     <span
                            dangerouslySetInnerHTML={{
                                __html: presentation.copyright,
                            }}
                        ></span></p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
