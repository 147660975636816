import React from "react";
import mockupphone2 from '../assets/img/mockupphone2.png'
import { useEffect, useState } from "react";
import api from "../api"; // Assurez-vous que ce fichier est bien configuré
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

const ContactForm = () => {
    const [captchaToken, setCaptchaToken] = useState(null);
    const [recaptchaKey, setRecaptchaKey] = useState("");
    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        societe: "",
        email: "",
        telephone: "",
        ville: "",
        pays: "",
        objet_message: "",
        message: "",
    });
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // Récupération de la clé publique reCAPTCHA
    useEffect(() => {
        const fetchRecaptchaKey = async () => {
            try {
                const response = await api.get("/recaptcha-key");
                setRecaptchaKey(response.data.site_key);

                setRecaptchaKey(response.data.site_key);
            } catch (error) {
                console.error("Erreur lors de la récupération de la clé reCAPTCHA :", error);
            }
        };

        fetchRecaptchaKey();
    }, []);


    // Gestion des changements dans les champs de formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Validation des données du formulaire
    const validateForm = () => {
        if (!formData.nom.trim()) return "Le champ 'Nom' est obligatoire.";
        if (!formData.prenom.trim()) return "Le champ 'Prénom' est obligatoire.";
        if (!formData.email.trim()) return "Le champ 'Email' est obligatoire.";
        if (!formData.objet_message.trim()) return "Le champ 'Objet de message' est obligatoire.";
        if (!formData.message.trim()) return "Le champ 'Message' est obligatoire.";
        // Si tous les champs obligatoires sont remplis
        return null;
    };

    // Soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Vérification si le reCAPTCHA est validé
        if (!captchaToken) {
            setErrorMessage("Veuillez valider le reCAPTCHA avant de soumettre le formulaire.");
            setTimeout(() => setErrorMessage(""), 5000); // Efface le message après 5 secondes
            return;
        }

        // Validation des champs du formulaire
        const validationError = validateForm();
        if (validationError) {
            setErrorMessage(validationError);
            setTimeout(() => setErrorMessage(""), 5000); // Efface le message après 5 secondes
            return;
        }

        // Préparation des données à envoyer au backend
        const formDataToSend = {
            ...formData,
            captchaToken, // Ajout du token reCAPTCHA
            societe: formData.societe.trim() || null, // Si vide, envoie null
            ville: formData.ville.trim() || null, // Si vide, envoie null
            pays: formData.pays.trim() || null, // Si vide, envoie null
        };

        try {
            // Appel à l'API backend pour envoyer les données
            const response = await api.post("/messages", formDataToSend);

            // Si le message a été envoyé avec succès
            setSuccessMessage("Votre message a été envoyé avec succès !");
            setErrorMessage("");

            // Réinitialise le formulaire et le token reCAPTCHA
            setFormData({
                nom: "",
                prenom: "",
                societe: "",
                email: "",
                telephone: "",
                ville: "",
                pays: "",
                objet_message: "",
                message: "",
            });
            setCaptchaToken(null);

            // Efface le message de succès après 5 secondes
            setTimeout(() => setSuccessMessage(""), 5000);
        } catch (error) {
            // Gère les erreurs reçues du backend
            if (error.response) {
                setErrorMessage(
                    error.response.data.message || "Une erreur s'est produite lors de l'envoi du formulaire."
                );
            } else {
                setErrorMessage("Impossible de joindre le serveur. Veuillez réessayer.");
            }

            // Efface le message d'erreur après 5 secondes
            setTimeout(() => setErrorMessage(""), 5000);
        }
    };


    return (
        <div className=" text-white py-10   justify-center items-center flex " style={{ fontFamily: 'sans-serif' }}>
            <div className="bg-white text-gray-900 rounded-lg shadow-lg p-1 lg:p-8  w-full  lg:w-[72%]">
                {/* Messages de retour */}
                {successMessage && (
                    <p className="text-green-600 mt-4  font-semibold  bg-green-100 p-5 ">
                        {successMessage}
                    </p>
                )}
                {errorMessage && (
                    <p className="text-red-600 mt-4 font-semibold bg-red-100 border border-red-500 p-3 rounded-md">
                        {errorMessage}
                    </p>
                )}

                <form className="p-1" onSubmit={handleSubmit}
                >
                    {/* Ligne 1 : Nom et Prénom */}
                    <div className="flex flex-wrap xl:gap-6 md:w-[100%]">
                        <div className="w-full md:w-[50%] 2Xl:w-[45%] xl:w-[45%] px-2 mb-4">
                            <input
                                type="text"
                                name="nom"
                                value={formData.nom}
                                onChange={handleChange}
                                className="w-full border-b-2 border-[#00262d] p-2 focus:outline-none placeholder-gray-800 focus:placeholder-gray-600"
                                placeholder="Nom *"
                                required
                            />
                        </div>
                        <div className="w-full md:w-[50%] 2Xl:w-[45%] xl:w-[45%] px-2 mb-4">
                            <input
                                type="text"
                                name="prenom"
                                value={formData.prenom}
                                onChange={handleChange}
                                className="w-full border-b-2 placeholder-gray-800 focus:placeholder-gray-600 border-[#00262d] p-2 focus:outline-none"
                                placeholder="Prénom *"
                                required
                            />
                        </div>
                    </div>

                    {/* Ligne 2 : Société */}
                    <div className="flex flex-wrap xl:gap-6 md:w-[100%]">
                        <div className="w-full md:w-[50%] 2Xl:w-[45%] xl:w-[45%] px-2 mb-4">
                            <input
                                type="text"
                                name="societe"
                                value={formData.societe}
                                onChange={handleChange}
                                className="w-full border-b-2 border-[#00262d] placeholder-gray-800 focus:placeholder-gray-600 p-2 focus:outline-none"
                                placeholder="Société"
                            />
                        </div>
                    </div>

                    {/* Ligne 3 : Email et Téléphone */}
                    <div className="flex flex-wrap xl:gap-6 md:w-[100%]">
                        <div className="w-full md:w-[50%] 2Xl:w-[45%] xl:w-[45%] px-2 mb-4">
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full border-b-2 border-[#00262d] placeholder-gray-800 focus:placeholder-gray-600 p-2 focus:outline-none"
                                placeholder="Email *"
                                required
                            />
                        </div>
                        <div className="w-full md:w-[50%] 2Xl:w-[45%] xl:w-[45%] px-2 mb-4">
                            <input
                                type="text"
                                name="telephone"
                                value={formData.telephone}
                                onChange={handleChange}
                                className="w-full border-b-2 border-[#00262d] placeholder-gray-800 focus:placeholder-gray-600 p-2 focus:outline-none"
                                placeholder="Téléphone "
                            />
                        </div>
                    </div>

                    {/* Ligne 4 : Ville et Pays */}
                    <div className="flex flex-wrap xl:gap-6 md:w-[100%]">
                        <div className="w-full md:w-[50%] 2Xl:w-[45%] xl:w-[45%] px-2 mb-4">
                            <input
                                type="text"
                                name="ville"
                                value={formData.ville}
                                onChange={handleChange}
                                className="w-full border-b-2 border-[#00262d] placeholder-gray-800 focus:placeholder-gray-600 p-2 focus:outline-none"
                                placeholder="Ville "
                            />
                        </div>
                        <div className="w-full md:w-[50%] 2Xl:w-[45%] xl:w-[45%] px-2 mb-4">
                            <input
                                type="text"
                                name="pays"
                                value={formData.pays}
                                onChange={handleChange}
                                className="w-full border-b-2 border-[#00262d] placeholder-gray-800 focus:placeholder-gray-600 p-2 focus:outline-none"
                                placeholder="Pays "
                            />
                        </div>
                    </div>

                    {/* Ligne 5 : Objet */}
                    <div className="flex flex-wrap xl:gap-6 md:w-[100%]">
                        <div className="w-full px-2 mb-4">
                            <input
                                type="text"
                                name="objet_message"
                                value={formData.objet_message}
                                onChange={handleChange}
                                className="w-full border-b-2 border-[#00262d] placeholder-gray-800 focus:placeholder-gray-600 p-2 focus:outline-none"
                                placeholder="Objet de message *"
                                required
                            />
                        </div>
                    </div>

                    {/* Ligne 6 : Message */}
                    <div className="mb-4">
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows="4"
                            className="w-full border-b-2 border-[#00262d] placeholder-gray-800 focus:placeholder-gray-600 p-2 focus:outline-none"
                            placeholder="Message *"
                            required
                        ></textarea>
                    </div>

                    <div className="mb-4 flex-col space-y-4  lg:gap-0 lg:flex-row md:flex-row md:justify-around flex  lg:justify-between lg:mx-4">

                        <div className="flex justify-center">  {recaptchaKey ? (

                            <ReCAPTCHA
                                sitekey={recaptchaKey} // Utilise la clé récupérée
                                onChange={(token) => setCaptchaToken(token)} // Stocke le token
                            />

                        ) : (
                            <p>Chargement du reCAPTCHA...</p>
                        )}</div>
                        <div className="flex flex-col md:flex-row items-center justify-between">
                            <button
                                type="submit"
                                className="bg-[#042a32] cursor-pointer text-white rounded-md px-7 py-3 hover:bg-teal-600"
                            >
                                ENVOYER
                            </button>
                        </div>
                    </div>

                    {/* Bouton d'envoi */}


                    {/* Messages de retour */}
                    {/* Messages de retour */}


                </form>
            </div>
            <div className="lg:flex hidden">
                <img
                    src={mockupphone2}
                    alt="Project"
                    className="w-auto h-auto rounded-lg mb-5 md:mb-0"
                />
            </div>
        </div>
    );
};

export default ContactForm;
