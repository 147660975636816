import axios from 'axios';

const api = axios.create({
    baseURL: 'https://noelpicart.fr/admin/public/api', // URL de votre backend Laravel
    // baseURL: "http://127.0.0.1:8000/api", // L'URL de votre backend Laravel
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
});

export default api;
