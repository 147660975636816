

import React, { useState, useEffect } from "react";
import flecheDr from "../assets/img/fleche-Dr.png";
import flecheGa from "../assets/img/fleche-Ga.png";
import api from "../api";

const BestOfProjects = () => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fonction pour aller au slide suivant
    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % projects.length);
    };

    // Fonction pour aller au slide précédent
    const prevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? projects.length - 1 : prev - 1));
    };

    // Récupération des projets depuis l'API
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await api.get("/projects");

                if (response.data.success && Array.isArray(response.data.data)) {
                    setProjects(response.data.data.sort((a, b) => a.order - b.order));
                } else {
                    console.error("Format inattendu :", response.data);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des projets :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    // Mise en place du défilement automatique
    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 2500); // 3 secondes

        // Nettoie l'intervalle lorsque le composant est démonté ou que le slide change
        return () => clearInterval(interval);
    }, [projects]); // Redémarre l'intervalle lorsque les projets sont chargés

    // Gestion des états "chargement" ou "aucun projet"
    if (loading) {
        return <div
            role="status"
            className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
        >
            <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
                <svg
                    className="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
            </div>
            <div className="w-full">
                <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-700 w-48 mb-4" />
                <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5" />
                <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 mb-2.5" />
                <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5" />
                <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5" />
                <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[360px]" />
            </div>
            <span className="sr-only">Loading...</span>
        </div>
            ;
    }

    if (!projects.length) {
        return <p>Aucun projet disponible.</p>;
    }

    return (
        <div className="text-white py-1 px-1" style={{ fontFamily: "sans-serif" }}>
            <div className="flex flex-col items-center">
                <div className="flex flex-col md:flex-row bg-teal-700 rounded-lg p-5 md:p-10">
                    <img
                        // src={`http://localhost:8000/storage/${projects[currentSlide]?.image}`}
                        src={`https://noelpicart.fr/admin/public/storage/${projects[currentSlide]?.image}`}

                        alt="Project"
                        className="w-full md:w-1/3 h-auto object-contain rounded-lg mb-5 md:mb-0 transition-opacity duration-500 ease-in-out opacity-0"
                        style={{ opacity: 1 }} // Cette image est visible

                    />
                    <div className="md:ml-5">
                        {projects[currentSlide]?.titre && (
                            <h3
                                className="text-lg mb-3 text-justify"
                                style={{ fontFamily: "DM Sans Merlin" }}
                                dangerouslySetInnerHTML={{
                                    __html: projects[currentSlide].titre,
                                }}
                            ></h3>
                        )}
                        {projects[currentSlide]?.description && (
                            <p
                                className="lg:text-lg text-sm text-justify"
                                style={{ fontFamily: "DM Sans Merlin" }}
                                dangerouslySetInnerHTML={{
                                    __html: projects[currentSlide].description,
                                }}
                            ></p>
                        )}
                    </div>
                </div>

                <div className="flex justify-center items-center mt-5 relative">
                    <button
                        onClick={prevSlide}
                        className="mx-2 z-50 text-white p-2 rounded-full"
                    >
                        <img src={flecheGa} alt="Précédent" className="w-full h-7 rounded-lg" />
                    </button>
                    <div className="absolute w-[300px] h-0.5 bg-gray-600 lg:top-1/2 top-1/2 md:top-1/2 transform -translate-y-1/3 z-30"></div>
                    <button
                        onClick={nextSlide}
                        className="mx-2 z-50 text-white p-2 rounded-full"
                    >
                        <img src={flecheDr} alt="Suivant" className="w-full h-7 rounded-lg" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BestOfProjects;
