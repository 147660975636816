

// export default Paiement;
import React from "react";
import logoNP from "../assets/img/logo-NP.png";
import logostripe from "../assets/img/logo-stripe.png";
import axios from "axios";
import api from "../api";
import { useNavigate } from "react-router-dom"; // Pour rediriger après paiement

const Paiement = () => {
 
  // Fonction simulée pour rediriger après paiement (ajustable)
  const handlePayment = async (event) => {
    event.preventDefault();

    // Récupérer les données du formulaire
    const nom = document.getElementById("nom").value.trim();
    const facture = document.getElementById("facture").value.trim();
    const montant = parseFloat(document.getElementById("montant").value);

    // Validation des champs
    if (!nom || !facture || isNaN(montant) || montant <= 0) {
      alert("Veuillez remplir tous les champs correctement avant de procéder au paiement.");
      return;
    }

    try {
      // Appel au backend pour créer une session Stripe
      const response = await api.post("/create-checkout-session", {
        client_name: nom,
        facture: facture,
        amount: montant,
        product_name: `Facture ${facture} de ${nom}`,
      });

      if (response.data.url) {
        const width = 800; // Largeur de la popup
        const height = 600; // Hauteur de la popup
        const left = (window.innerWidth - width) / 2; // Centrer horizontalement
        const top = (window.innerHeight - height) / 2; // Centrer verticalement

        // Ouvre Stripe Checkout dans une popup
        const stripePopup = window.open(
          response.data.url,
          "PopupStripeCheckout",
          `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
        );

        if (!stripePopup) {
          return;
        }

        // Vérifie si la popup Stripe est fermée après paiement
        const interval = setInterval(() => {
          if (stripePopup.closed) {
            clearInterval(interval); // Arrête l'intervalle
            window.close(); // Ferme la popup actuelle
          }
        }, 1000); // Vérifie toutes les 1 seconde
      } else {
        alert("Erreur : aucune URL Stripe retournée par le serveur.");
      }
    } catch (error) {
      console.error("Erreur lors de la création de la session Stripe :", error);
      alert("Une erreur s'est produite. Veuillez réessayer.");
    }
  };

  return (
    <div
      className="min-h-screen bg-[#08242c] flex flex-col justify-center items-center text-white font-sans"
      style={{ padding: "20px" }}
    >
      <div className="max-w-xl p-6 text-center">
        <div className="text-white flex justify-center items-center">
          <img
            src={logoNP}
            alt="Project"
            className="w-auto h-auto rounded-lg"
          />
        </div>
        <div className="space-y-6">
          <div className="mt-0">
            <div className="bg-[#383838]">
              <p className="font-medium text-left px-4 mb-2 lg:text-md text-sm py-2">
                RÉGLEZ VOS PRESTATIONS EN LIGNE...
              </p>
            </div>
            <div className="my-4">
              <p className="lg:text-[16px] text-[14px] text-justify px-2 mt-2">
                Indiquez votre nom et numéro de facture, bon de commande ou devis,
                et le montant que vous désirez régler avec notre TPE virtuel STRIPE.
              </p>
            </div>
          </div>
          <form className="bg-[#dbdbdb] text-black py-3 px-2 space-y-2" onSubmit={handlePayment}
          >
            <div className="flex   items-center gap-2 lg:justify-end ">
              <label htmlFor="nom" className="lg:text-[15px] w-1/2 lg:w-auto sm:text-[13px]  text-[10px]   text-center">
                VOTRE NOM OU ENTREPRISE :
              </label>
              <input
                type="text"
                id="nom"
                name='nom'
                placeholder=""
                className=" p-2 mt-1 w-1/2 lg:w-auto  border border-black"
                required
              />
            </div>
            <div className="flex   items-center gap-2 lg:justify-end ">
              <label htmlFor="nom" className="lg:text-[15px] w-1/2 sm:text-[13px]  text-[10px]   text-center">
                N°.FACTURE OU  COMMANDE :
              </label>
              <input
                type="text"
                id="facture"
                name='facture'
                placeholder=""
                className=" p-2 mt-1 w-1/2 lg:w-auto  border border-black"
                required
              />
            </div>
            <div className="flex   items-center gap-2 lg:justify-end ">
              <label htmlFor="nom" className="lg:text-[15px] w-1/2 sm:text-[13px]  text-[10px]   text-center">
                MONTANT A REGLER EN EUROS :
              </label>
              <input
                type="number"
                id="montant"
                name='montant'
                placeholder=""
                className=" p-2 mt-1 w-1/2 lg:w-auto     border border-black"
                required
              />
            </div>

          </form>
          <div className="my-4 bg-[#85aed7] py-5 flex justify-center items-center flex-col">
            <img
              src={logostripe}
              alt="Project"
              className="w-auto h-20 rounded-lg md:mb-0"
            />
            <button
              type="button"
              className="bg-[#18516b] text-white w-[90%] mt-3 py-1 hover:bg-blue-700 text-[24px]"
              onClick={handlePayment}
            >
              PAYER
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paiement;
