import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PaiementPage from "./components/PaiementPage";


const App = () => {
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/paiement" element={<PaiementPage />} />
          

            </Routes>
        </BrowserRouter>
    );
};

export default App;
